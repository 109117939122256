<template>
	<main-table-a>
		<template v-if="show_table">
			<div slot="table" class="f1 df fdc">
				<el-form :inline="true" :model="params" :size="theme.size">
					<el-form-item>
						<el-date-picker v-model="params.month" type="month" value-format="yyyy-MM" placeholder="选择月份"></el-date-picker>
					</el-form-item>
					<el-form-item>
						<el-cascader style="width: 100%;" placeholder="请选择所属部门" v-model="params.departments" :options="tree_departments" :props="{expandTrigger: 'hover', value: 'unique_id', label: 'name', checkStrictly: true}" filterable clearable></el-cascader>
					</el-form-item>
					<el-form-item>
						<el-select v-model="params.employee_id" placeholder="请选择员工" filterable multiple collapse-tags>
							<el-option v-for="employee in employees" :key="employee.id" :label="employee.name" :value="employee.id"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="getSalaries({ ...params, page: 1})" plain>查询</el-button>
						<el-button type="primary" @click="create_dialog = true">创建</el-button>
						<el-button type="primary" @click="$refs['create'].open({})">添加明细</el-button>
						<el-button type="text" @click="handleCommand('export')">导出选中</el-button>
					</el-form-item>
				</el-form>
				<div class="f1 pr">
					<el-table ref="salaries_table" class="scroll-wrapper pa salaries" height="100%" :data="salaries" :size="theme.size" :row-class-name="tableRowClassName">
						<el-table-column type="selection" width="42"></el-table-column>
						<el-table-column align="center" label="签名">
							<template slot-scope="scope" v-if="!$moment().isSameOrBefore(scope.row.period_at, 'month')">
								<img class="db" :src="scope.row.signatured_card" v-if="scope.row.signatured_card">
								<template v-else>未签名</template>
								<!-- <el-link type="primary" :disabled="!$utils.update($api.URI_SALARIES)" @click="onSignature(scope.row)" v-else>点击签名</el-link> -->
							</template>
						</el-table-column>
						<el-table-column align="center" label="姓名" prop="employee.name" min-width="90">
							<template slot-scope="scope">
								<el-tooltip effect="dark">
									<div slot="content">
										<template v-if="scope.row.employee.enrollment">
											<p class="mb5">真实姓名：{{scope.row.employee.enrollment.name}}</p>
											<p class="mb5">入职类型：{{enrollment_types[scope.row.employee.enrollment.type] || '未知'}}</p>
											<p class="mb5">证件类型：{{enrollment_id_types[scope.row.employee.enrollment.id_type] || '未知'}}</p>
											<p class="mb5">证件号码：{{scope.row.employee.enrollment.id_number}}</p>
											<p class="mb5">银行卡号：{{scope.row.employee.enrollment.deposit_bank || '未登记'}} {{scope.row.employee.enrollment.bank_number || ''}}</p>
											<p class="mb5">入职时间：{{scope.row.employee.enrollment.hired_at}}</p>
											<p class="mb5" v-if="scope.row.employee.enrollment.departure">离职时间：{{scope.row.employee.enrollment.departure.separated_at}}</p>
										</template>
										<p v-else>未办理入职登记或审核未通过</p>
									</div>
									<span>{{scope.row.employee.name}}</span>
								</el-tooltip>
							</template>
						</el-table-column>
						<el-table-column align="center" label="月份" min-width="80">
							<template slot-scope="scope">{{scope.row.period_at}}</template>
						</el-table-column>
						<el-table-column prop="base_wage" label="基本工资" min-width="70" :formatter="({base_wage}) => {  return +base_wage; }"></el-table-column>
						<!-- <el-table-column prop="work_days" label="应出勤" min-width="60" :formatter="({work_days}) => {  return +work_days; }"></el-table-column>
						<el-table-column prop="actual_days" label="实出勤" min-width="60" :formatter="({actual_days}) => {  return +actual_days; }"></el-table-column> -->
						<template v-if="fields.filter(({type}) => type == 0).length">
							<el-table-column min-width="60" v-for="column in fields.filter(({type}) => type == 0)" :key="column.id" :label="column.name">
								<el-link slot-scope="scope" type="primary" @click="onField(scope.row, column.id)">{{+scope.row['column_'+column.id]}}</el-link>
							</el-table-column>
						</template>
						<el-table-column label="应发" align="center" v-if="fields.filter(({type}) => type == 1).length">
							<el-table-column align="center" min-width="80" v-for="column in fields.filter(({type}) => type == 1)" :key="column.id" :label="column.name">
								<el-link slot-scope="scope" type="primary" @click="onField(scope.row, column.id)">{{+scope.row['column_'+column.id]}}</el-link>
							</el-table-column>
							<el-table-column align="center" min-width="80" label="其它">
								<el-link slot-scope="scope" type="primary" @click="onOther(scope.row, 1)">{{+scope.row.column_other_1}}</el-link>
							</el-table-column>
							<el-table-column align="center" min-width="80" label="合计" prop="column_type_1" :formatter="({column_type_1}) => { return +column_type_1; }"></el-table-column>
						</el-table-column>
						<el-table-column label="代扣" align="center" v-if="fields.filter(({type}) => type == 2).length">
							<el-table-column align="center" min-width="80" v-for="column in fields.filter(({type}) => type == 2)" :key="column.id" :label="column.name">
								<el-link slot-scope="scope" type="primary" @click="onField(scope.row, column.id)">{{+scope.row['column_'+column.id]}}</el-link>
							</el-table-column>
							<el-table-column align="center" min-width="80" label="其它">
								<el-link slot-scope="scope" type="primary" @click="onOther(scope.row, 2)">{{+scope.row.column_other_2}}</el-link>
							</el-table-column>
							<el-table-column align="center" min-width="80" label="合计" prop="column_type_2" :formatter="({column_type_2}) => { return +column_type_2; }"></el-table-column>
						</el-table-column>
						<el-table-column align="center" label="实发" min-width="100">
							<template slot-scope="scope">
								<span v-if="scope.row.signatured_card">{{scope.row.actual}} 元</span>
								<span v-else>{{(+scope.row.actual || +(scope.row.column_type_1 - scope.row.column_type_2).toFixed(2))}} 元</span>
							</template>
						</el-table-column>
					</el-table>
				</div>
			</div>
			<el-pagination
				slot="footer"
				:layout="$config.PAGE_LAYOUT"
				:page-sizes="$config.PAHE_SIZES"
				:page-size="params.perPage"
				:current-page="params.page"
				:total="params.total"
				@size-change="(v) => { getSalaries({ ...params, page: 1, perPage: v }) }"
				@current-change="(v) => { getSalaries({ ...params, page: v }) }"
				background>
			</el-pagination>
		</template>
		<template v-else>
			<el-result slot="table" icon="warning" title="警告提示" subTitle="暂不支持查看薪酬列表，请在点击下方按钮初始化薪酬配置默认字段"></el-result>
		</template>
		<template slot="other">
			<el-dialog title="确认签名" width="640px" :visible.sync="salaries_dialog" append-to-body destroy-on-close>
				<div class="cf1 fs100 fwb tac bcfd" style="line-height: 300px;">签 名 区 域</div>
				<vue-esign ref="esign" class="br10 cp" style="margin-top: -300px; border: 1px dashed #999;" :isCrop="false" :lineWidth="8" :width="600" :height="300"></vue-esign>
				<template slot="footer">
					<el-button type="danger" :size="theme.size" @click="$refs['esign'].reset()">清空签名</el-button>
					<el-button type="primary" :size="theme.size" @click="handleGenerate">提交签名</el-button>
				</template>
			</el-dialog>
			<el-dialog title="创建工资条" width="360px" :visible.sync="create_dialog" append-to-body destroy-on-close>
				<el-form label-width="80px" ref="create_form" :model="create" :rules="rules" :size="theme.size" status-icon>
					<el-form-item label-width="0px">
						<div class="lh1_5 c66 fs12" style="margin-top: -20px;">
							<p>先填写基本工资等配置薪酬，否则不会创建</p>
							<p>同一人只能在同一个月只创建一次</p>
							<p>未创建成功的配置薪酬后可继续创建</p>
						</div>
					</el-form-item>
					<el-form-item label="结算月份" prop="month">
						<el-date-picker type="month" value-format="yyyy-MM" placeholder="选择结算月份" v-model="create.month" :picker-options="pickerOptions" :editable="false" :clearable="false"></el-date-picker>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="submitForm('create_form')">确认提交</el-button>
					</el-form-item>
				</el-form>
			</el-dialog>
			<create ref="create" :fields="fields" :employees="employees" @refresh="getSalaries(params)"></create>
			<show ref="detail" :fields="fields" @refresh="getSalaries(params)"></show>
		</template>
	</main-table-a>
</template>

<style>
	.el-table.salaries .warning-row {
		background: oldlace;
	}

	.el-table.salaries .success-row {
		background: #f0f9eb;
	}
</style>

<script>
	import { mapState } from 'vuex';
	import moment from 'moment';
	import vueEsign from 'vue-esign';
	import mainTableA from '@/components/main-table-a';
	import create from './create';
	import show from './show';

	export default {
		components: {
			mainTableA,
			vueEsign,
			create,
			show
		},
		computed: {
			...mapState(['theme', 'oa_users', 'models', 'oa_departments']),
			tree_departments () {
				return this.$utils.buildTree(Object.values(this.oa_departments));
			},
			enrollment_dict () {
				if (!this.models) return {};
				return this.models.enrollments || {};
			},
			enrollment_types () {
				return this.enrollment_dict.types || {};
			},
			enrollment_id_types () {
				return this.enrollment_dict.id_types || {};
			}
		},
		filters: {
			format_at (t) {
				if (!t) return '无';
				return moment(t).format('Y-MM');
			}
		},
		methods: {
			tableRowClassName({row}) {
				if (!row.employee) return 'warning-row';
				if (row.employee.separated_at) return 'warning-row';
				if (row.signatured_card) return 'success-row';
				return '';
			},
			onSignature ({id}) {
				this.salary.id = id;
				this.salaries_dialog = true;
			},
			handleCommand (e) {
				const { selection } = this.$refs.salaries_table;
				if (!selection.length) return this.$message.error('请选择需要操作的工资条');
				switch (e) {
					case 'export':
						var header = ['结算年月', '员工姓名', '入职时间', '离职时间', '入职类型', '证件类型', '证件号码', '开户银行', '银行号码', '签名确认'];
						var filter = ['period_at', 'employee_name', 'hired_at', 'separated_at', 'enrollment_type', 'id_type', 'id_number', 'deposit_bank', 'bank_number', 'signatured'];
						if (this.fields.length) {
							for (let field of this.fields.filter(({type}) => type == 0)) {
								header.push(field.name);
								filter.push('column_'+field.id);
							}
							for (let field of this.fields.filter(({type}) => type == 1)) {
								header.push(field.name);
								filter.push('column_'+field.id);
							}
							header.push('其它');
							filter.push('column_other_1');
							header.push('应发');
							filter.push('column_type_1');
							for (let field of this.fields.filter(({type}) => type == 2)) {
								header.push(field.name);
								filter.push('column_'+field.id);
							}
							header.push('其它');
							filter.push('column_other_2');
							header.push('代扣');
							filter.push('column_type_2');
						}
						header.push('实发');
						filter.push('actual');
						selection.forEach((c) => {
							c.signatured = c.signatured_card ? '已电子签名' : '未签名';
							c.actual = (c.column_type_1 - c.column_type_2).toFixed(2);
							if (c.employee) {
								c.employee_name = c.employee.name;
								// 已办理入职登记才需要处理的信息
								const { enrollment } = c.employee;
								if (enrollment) {
									c.employee_name = enrollment.name || c.employee_name;
									c.enrollment_type = this.enrollment_types[enrollment.type];
									c.id_type = this.enrollment_id_types[enrollment.id_type];
									c.id_number = enrollment.id_number;
									c.deposit_bank = enrollment.deposit_bank;
									c.bank_number = enrollment.bank_number;
									c.hired_at = enrollment.hired_at;
									if (enrollment.departure) {
										c.separated_at = enrollment.departure.separated_at;
									}
								}
							}
							return c;
						});
						this.$confirm(`此操作将导出${selection.length}条内容, 是否继续？`, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {
							this.$utils.handleDownload([header], filter, selection, "薪酬导出" + this.$moment().format('YYYYMMDDHHmmss'), "薪酬列表");
						});
					break;
				}
			},
			handleGenerate () {
				this.$refs.esign.generate().then(signatured_card => {
					this.$confirm('提交后不能修改，请认真核对信息无误后提交签名。', '确认提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(async () => {
						const res = await this.$http.post(this.$api.URI_SALARIES, {...this.salary, signatured_card});
						const { code, msg: message } = res.data;
						if (code != 0) return this.$message.error(message);
						this.$message.success({
							message,
							onClose: () => {
								this.getSalaries({...this.params, page: 1});
								this.salaries_dialog = false;
							}
						});
					});
				}).catch(() => {
					this.$message.error("请在指定区域内签名！");
				});
			},
			onField (salary, salary_field_id) {
				this.$refs['detail'].open(salary, {salary_field_id});
			},
			onOther (salary, column_type) {
				this.$refs['detail'].open(salary, {column_type});
			},
			async getSalaries (params, loading = false) {
				const res = await this.$http.get(this.$api.URI_SALARIES, {params, headers: { loading }});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.salaries = result.data;
				this.fields = result.fields;
				this.employees = result.employees;
				this.show_table = (result.fields && result.fields.length);
				this.params = {
					...params,
					total: result.total,
					perPage: parseInt(result.per_page)
				}
			},
			async submitSalaries (data) {
				const res = await this.$http.post(this.$api.URI_SALARIES, data);
				const { code, msg: message } = res.data;
				if (code != 0) return this.$message.error(message);
				this.$message.success({
					message,
					onClose: () => {
						this.getSalaries({...this.params, page: 1});
						this.create_dialog = false;
					}
				});
			},
			submitForm (ref) {
				this.$refs[ref].validate(async (valid) => {
					if (!valid) return this.$message.error("提交表单有误！");
					this.submitSalaries(this.create);
				});
			}
		},
		data() {
			return {
				create: {},
				salary: {
					action: 'update',
				},
				fields: [],
				salaries: [],
				employees: [],
				salaries_dialog: false,
				create_dialog: false,
				show_table: true,
				params: {
					perPage: 10
				},
				rules: {
					month: [{ required: true, message: '请选择考核月份', trigger: 'blur' }]
				},
				pickerOptions: {
					disabledDate (time) {
						// 获取当前时间
						const now = new Date();
						// 获取当前年份和月份
						const currentYear = now.getFullYear();
						// 月份是从0开始的，所以要加1
						const currentMonth = now.getMonth() + 1;
						// 获取选择日期的年份和月份
						const selectYear = time.getFullYear();
						// 月份是从0开始的，所以要加1
						const selectMonth = time.getMonth() + 1;
						// 如果选择的年份大于当前年份，或者年份相同但月份大于当前月份，则禁用该日期
						return selectYear > currentYear || (selectYear === currentYear && selectMonth >= currentMonth);
					}
				}
			}
		},
		async created () {
			this.getSalaries(this.params, true);
		}
	};
</script>