<template>
	<el-dialog width="720px" :title="title+'明细列表'" :visible.sync="details_dialog" :fullscreen="theme.width < 720" @closed="details = [];" append-to-body destroy-on-close>
		<el-table class="scroll-wrapper" style="margin-top: -30px;" height="48vh" :data="details" :size="theme.size">
			<el-table-column label="操作人" min-width="90" :formatter="({employee}) => { return employee ? employee.name : '系统'; }"></el-table-column>
			<el-table-column label="数值" min-width="80">
				<template slot-scope="scope">
					<span v-if="salary.signatured_card">{{+scope.row.amount}}</span>
					<el-link type="primary" @click="$refs['create'].open({...scope.row})" v-else>{{+scope.row.amount}}</el-link>
				</template>
			</el-table-column>
			<el-table-column label="备注说明" prop="commit" min-width="160" show-overflow-tooltip></el-table-column>
			<el-table-column label="更新时间" prop="updated_at" min-width="150"></el-table-column>
			<el-table-column label="创建时间" prop="created_at" min-width="150"></el-table-column>
			<el-table-column label="操作" min-width="50" v-if="!salary.signatured_card">
				<el-link slot-scope="scope" type="primary" @click="delRow(scope.row)">删除</el-link>
			</el-table-column>
		</el-table>
		<el-pagination
			:layout="$config.PAGE_LAYOUT"
			:page-sizes="$config.PAHE_SIZES"
			:page-size="params.perPage"
			:current-page="params.page"
			:total="params.total"
			@size-change="(v) => { getDetails({ ...params, page: 1, perPage: v }) }"
			@current-change="(v) => { getDetails({ ...params, page: v }) }"
			background>
		</el-pagination>
		<create ref="create" :fields="fields" @refresh="getDetails(params); $emit('refresh')"></create>
	</el-dialog>
</template>

<script>
	import { mapState } from 'vuex';
	import create from './create';

	export default {
		components: {
			create
		},
		computed: {
			...mapState(['theme']),
			title () {
				var title = '其它';
				for (let field of this.fields) {
					if (this.params.salary_field_id == field.id) {
						title = field.name;
					}
				}
				return title;
			}
		},
		props: {
			fields: {
				type: Array
			}
		},
		methods: {
			open (salary, params) {
				if (!salary) return this.$message.error('未知操作薪酬');
				const { id: salary_id } = salary;
				this.salary = salary;
				this.getDetails({ salary_id, ...params }, true);
				this.details_dialog = true;
			},
			delRow ({id, amount, commit}) {
				this.$confirm((commit || '') + ' ' + amount, '确定要删除吗？', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					this.handleDetails({action: 'delete', id});
				});
			},
			async handleDetails (params) {
				const res = await this.$http.get(this.$api.URI_SALARIES_DETAILS, {params, headers: {loading: true}});
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.getDetails(this.params);
				this.$message.success(msg);
				this.$emit('refresh');
			},
			async getDetails (params, loading = false) {
				const res = await this.$http.get(this.$api.URI_SALARIES_DETAILS, {params, headers: { loading }});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.details = result.data;
				this.params = {
					...params,
					total: result.total,
					perPage: parseInt(result.per_page)
				}
			}
		},
		data() {
			return {
				salary: null,
				details: [],
				details_dialog: false,
				params: {
					perPage: 10
				}
			}
		}
	};
</script>